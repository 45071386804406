<template>
  <div
    class="modal fade"
    :id="`modalFieldDiagram`"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    :aria-labelledby="`modalFieldDiagramLabel`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header p-4">
          <h5 class="modal-title" :id="`modalFieldDiagramLabel`">
            <template v-if="selectedField.length > 0">
              <i class="bi bi-diagram-3 me-1"></i>
              {{ selectedField[0].value }}
              {{
                $t(
                  "Components.FieldDiagramModal.Title",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </template>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" :class="{ 'p-0': !hidden }">
          <template v-if="hidden">
            <div id="loading-bar">
              <div
                class="d-flex align-items-center justify-content-center h-100 w-100"
              >
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <b
                class="d-flex align-items-center mt-2 mb-0 justify-content-center font-weight-normal"
                >{{
                  $t(
                    "Buttons.PleaseWait",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</b
              >
            </div>
          </template>
          <div class="dx-viewport" v-else>
            <div class="border-primary rounded border w-100">
              <FormSelect
                type="SelectWithRemoteDataAndInternalSearch"
                @onChange="onChangeField"
                :isParameters="true"
                :selectedData="selectedField"
                :isGatewayRequest="true"
                :requestUrl="
                  String.format(
                    '/Lcdp-SummaryFields?coId={0}',
                    this.$route.params.customObjectId
                  )
                "
              />
            </div>
            <DxDiagram
              id="fieldDiagram"
              ref="diagram"
              :simple-view="true"
              :readOnly="true"
            >
              <DxNodes
                :data-source="dataSource"
                :key-expr="'index'"
                :text-expr="'title'"
                :parent-key-expr="'headIndex'"
                :width-expr="itemWidthExpr"
              >
                <DxAutoLayout :type="'tree'" />
              </DxNodes>
              <DxExport
                :enabled="true"
                :file-name="selectedField[0].value"
              ></DxExport>
              <DxToolbox :visibility="'disabled'" />
              <DxPropertiesPanel :visibility="'disabled'" />
            </DxDiagram>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
#fieldDiagram {
  height: 500px;
  width: 100%;
}
</style>
<script>
import {
  DxDiagram,
  DxNodes,
  DxAutoLayout,
  DxToolbox,
  DxPropertiesPanel,
  DxExport,
} from "devextreme-vue/diagram";
import ArrayStore from "devextreme/data/array_store";
export default {
  name: "FieldDiagramModal",
  components: {
    DxDiagram,
    DxNodes,
    DxAutoLayout,
    DxToolbox,
    DxExport,
    DxPropertiesPanel,
  },
  props: ["fields", "field"],
  data() {
    return {
      dataSource: null,
      hidden: true,
      selectedField: [],
    };
  },
  methods: {
    itemWidthExpr(obj, value) {
      if (value === undefined) obj.width = value;
      else return obj.width || (obj.type === "group" && 1.5) || 1;
      return 2;
    },
    getDiagramData(fields, field) {
      var $loopRepeat = true,
        self = this,
        index = 1,
        hierarchicalFields = [
          { index: index, title: field.name, publicId: field.publicId },
        ],
        loopFieldPublicIds = [field.publicId];

      while ($loopRepeat) {
        loopFieldPublicIds.forEach((publicId) => {
          var childs = fields.filter(function (f) {
            return (
              !String.isNullOrWhiteSpace(f.calculateTriggerFieldPublicIds) &&
              f.calculateTriggerFieldPublicIds
                .split(self.$systemSeparator)
                .includes(publicId)
            );
          });
          if (childs.length > 0) {
            var z = 0;
            childs.forEach((childField) => {
              var childPublicId = childField.publicId,
                childFieldControllingItems = fields.filter(function (f) {
                  return (
                    !String.isNullOrWhiteSpace(
                      f.calculateTriggerFieldPublicIds
                    ) &&
                    f.calculateTriggerFieldPublicIds
                      .split(self.$systemSeparator)
                      .includes(childPublicId)
                  );
                });

              var record = hierarchicalFields.find(
                  (f) => f.publicId == childPublicId
                ),
                indexValue = ++index;
              if (!record) {
                hierarchicalFields.push({
                  index: indexValue,
                  headIndex: hierarchicalFields[0].index,
                  title: childField.name,
                  publicId: childField.publicId,
                });
              }
              if (childFieldControllingItems.length > 0) {
                childFieldControllingItems.forEach((field, i) => {
                  hierarchicalFields.push({
                    index: ++index,
                    headIndex: record ? record.index : indexValue,
                    title: field.name,
                    publicId: field.publicId,
                  });
                });

                if (z == 0) loopFieldPublicIds = [];
                z++;

                loopFieldPublicIds.push(childPublicId);
                $loopRepeat = true;
              } else {
                $loopRepeat = false;
              }
            });
          } else {
            $loopRepeat = false;
          }
        });
      }

      return hierarchicalFields;
    },
    onChangeField(selected) {
      this.selectedField = [{ key: selected.key, value: selected.value }];

      this.dataSource = new ArrayStore({
        key: "index",
        data: this.getDiagramData(this.fields, {
          publicId: selected.key,
          name: selected.value,
        }),
      });
    },
  },
  watch: {
    field() {
      this.selectedField = [
        { key: this.field.publicId, value: this.field.name },
      ];

      this.hidden = true;

      this.dataSource = new ArrayStore({
        key: "index",
        data: this.getDiagramData(this.fields, this.field),
      });

      setTimeout(() => {
        this.hidden = false;
      }, 1000);
    },
  },
};
</script>
