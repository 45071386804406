<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "CustomObjects.Fields",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <router-link
        :to="{
          path: '/ProjectDesign/Field/List',
        }"
        class="btn btn-action-list-page"
      >
        <i class="bi bi-list"></i>
        {{ $t("Fields.AllData", {}, { locale: this.$store.state.activeLang }) }}
      </router-link>
      <router-link
        :to="{
          name: 'CustomObjectNewField',
          params: { customObjectId: this.$route.params.customObjectId },
        }"
        class="btn btn-success btn-action-new-page"
      >
        <i class="bi bi-plus"></i>
        {{
          $t("Fields.NewButton", {}, { locale: this.$store.state.activeLang })
        }}
      </router-link>
    </div>
  </div>
  <Grid
    :settings="gridSettings"
    :columns="gridColumns"
    :rows="fieldList"
    @changeOrderSuccessful="getFields"
    @onFieldDiagramButtonClick="onFieldDiagramButtonClick"
    @onTranslationButtonClick="onTranslationButtonClick"
  />
  <FieldDiagram :fields="fieldList" :field="currentField" />
  <TranslationModal
    :keyTypeId="keyTypeId"
    :parentPublicId="customObjectId"
    :formulaName="formulaName"
    :keyName="this.keyName"
    :keyItemPublicId="keyItemPublicId"
  />
</template>
<script>
import FieldDiagram from "@/components/devexpress/diagram/FieldDiagramModal";
import { showModal } from "@/core/helpers/dom";
export default {
  name: "CustomObjectFields",
  components: {
    FieldDiagram,
  },
  data() {
    return {
      keyTypeId: 2,
      keyName: "",
      customObjectId: "",
      formulaName: "",
      keyItemPublicId: "",
      gridSettings: {
        action: "CustomObjectFields",
        requestUrl: String.format(""),
        requestUrlRouteParamNames: [
          { key: "customObjectPublicId", value: "customObjectId" },
        ],
        routeRequiredParameters: [
          {
            key: "fieldId",
            value: "publicId",
            isRouteParameter: false,
          },
          {
            key: "customObjectId",
            value: "customObjectId",
            isRouteParameter: true,
          },
        ],

        serialNoLink: `#/CustomObject/EditField/__CUSTOMOBJECTPUBLICID__&fieldId=`,

        allowSearchPanel: true,
        allowExcelExport: true,
        allowPaging: false,
        allowDeleting: true,
        deleteActionUrl: "/Lcdp-FieldDelete",

        isGatewayRequest: true,

        allowDragAndDrop: true,
        changeOrderUrl: "/Lcdp-FieldChangeOrder",
        changeOrderModelParentProperties: [
          { key: "parentPublicId", value: "customObjectId" },
        ],
        autoOrderProcess: false,

        buttons: [
          {
            name: "diagram",
            cssClass: "btn-info",
            iconClass: "bi-diagram-2",
            routeButton: false,
            emitMethodName: "onFieldDiagramButtonClick",
            attributes: {
              "data-bs-toggle": "modal",
              "data-bs-target": "#modalFieldDiagram",
            },
            iconAttributes: {
              "data-bs-html": "true",
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "top",
              title: this.$t(
                "Fields.FieldDiagram",
                {},
                { locale: this.$store.state.activeLang }
              ),
            },
          },
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "CustomObjectEditField",
              params: {
                customObjectId: "",
                fieldId: "",
              },
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "Fields.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 200,
          template: "customObjectFieldNameTemplate",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Fields.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Fields.FieldType",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "fieldType",
          type: "string",
          visible: true,
          width: 0,
          template: "customObjectFieldTypeTemplate",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Fields.DefaultValue",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "defaultValue",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Fields.Required",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isRequired",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Fields.IsUnique",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isUnique",
          type: "boolean",
          visible: true,
          width: 100,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
      ],
      translateRecordId: null,
      translateFormulaName: null,
      currentField: {},
      fieldList: [],
    };
  },
  methods: {
    onFieldDiagramButtonClick(rowData, $event) {
      this.currentField = rowData;
      // showModal(document.getElementById("modalFieldDiagram"));
    },
    onTranslationButtonClick(rowData) {
      this.keyItemPublicId = rowData.publicId;
      this.customObjectId = rowData.customObjectPublicId;
      this.formulaName = rowData.formulaName;
      this.keyName = rowData.name;
      // showModal(document.getElementById("translationModal"));
    },
    getFields() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-FieldList?customObjectPublicId={0}",
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          this.fieldList = response.data.items;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    if (this.$store.getters._isMultiLanguage) {
      this.gridSettings.buttons.push({
        name: "translation",
        cssClass: "btn-light translation",
        iconClass: "bi bi-translate",
        routeButton: false,
        emitMethodName: "onTranslationButtonClick",
        attributes: {
          "data-bs-toggle": "modal",
          "data-bs-target": "#translationModal",
        },
        iconAttributes: {
          "data-bs-toggle": "tooltip",
          "data-bs-placement": "top",
          title: this.$t(
            "Components.TranslationModal.Title",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      });
    }
    this.getFields();
  },
  watch: {
    "$route.params.customObjectId"(newValue) {
      if (!String.isNullOrWhiteSpace(newValue)) {
        this.getFields();
      }
    },
  },
};
</script>
